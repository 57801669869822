<template>
    <!--
    Страница пользователя
    -->
    <el-container direction="vertical">

        <el-container direction="vertical" class="section">

            <el-row class="header" type="flex" justify="space-between" style="align-items: center">
                <span>Общие данные</span>
                <span style="text-align: end">
                    <template v-if="$is_admin()">
                        <dom-button2 class="set-admin-button" v-if="isUnregistred"  @click="registerUserDialog = true" style="margin-bottom: 5px">Зарегистрировать пользователя</dom-button2>
                        <register-user-dialog v-if="isUnregistred" :user="currentUser" v-model="registerUserDialog"/>
                    </template>

                    <dom-button2 class="remove-button" style="margin-left: 10px" @click="removeUserDialog = true" right-icon="fas el-icon-fa-times-circle">Удалить пользователя</dom-button2>
                    <code-confirmation-dialog v-model="removeUserDialog" :request-code="onGetCode"  :apply-code="onRemoveUser"/>
                </span>
            </el-row>

            <dom-selectable-label v2
                                  :value="currentUser.role.roleCd"
                                  :items="roles || []"
                                  :editable="false"
                                  prefix-text="Роль:"
                                  value-key="roleCd"
                                  text-key="roleNm"
                                  use-key
                                  @input="onSaveUserRole"
            />

            <dom-editable-label v2 :value="currentUser.name" prefix-text="ФИО:" @input="onSaveUserField('name', $event)" v-if="currentUser.jurForm === 'fl'"/>
            <dom-editable-label v2 :value="currentUser.name" prefix-text="Наименование:" @input="onSaveUserField('name', $event)" v-else/>
            <dom-editable-label v2 :value="currentUser.email" prefix-text="Email:" @input="onSaveUserField('email', $event)" v-if="needEmail"/>

            <template v-if="needBirthDate && currentUser.jurForm === 'fl'">
                <dom-datepicker-label v2 :value="currentUser.birthDate | ru_date" prefix-text="Дата рождения:" @input="onSaveUserField('birthDate', $event, 'date')"/>
            </template>
            <template v-if="$is_admin()">
                <dom-editable-label v2 :value="currentUser.phoneNumber" prefix-text="Номер телефона:" @input="onSaveUserField('phoneNumber', $event, 'string')"/>
            </template>

            <template v-if="currentUser.jurForm === 'org'">
                <dom-editable-label v2 :value="currentUser.inn" prefix-text="ИНН:" @input="onSaveUserField('inn', $event, 'long')"/>
                <dom-editable-label v2 :value="currentUser.ogrn" prefix-text="ОГРН:" @input="onSaveUserField('ogrn', $event, 'long')"/>
            </template>


        </el-container>

        <el-container direction="vertical" class="section">
            <el-row class="header">Данные Администратора общего собрания собственников</el-row>

            <dom-editable-label v2 :value="currentUser.passportNo" prefix-text="Паспортные данные:" @input="onSaveUserField('passportNo', $event)"/>
            <dom-editable-label v2 :value="currentUser.address" prefix-text="Фактический адрес:" @input="onSaveUserField('address', $event)"/>
            <dom-editable-label v2 :value="currentUser.phoneNumber" prefix-text="Номер телефона:" @input="onSaveUserField('phoneNumber', $event)"/>
        </el-container>

        <el-container direction="vertical" class="section" v-if="hasProperty">
            <el-row class="header"  type="flex" justify="space-between" style="align-items: center">
                Недвижимость
                <dom-button2 class="add-prop-button" @click="onAddProperty">Добавить объект</dom-button2>
                <add-realty-dialog v-if="addPropertyDialog" v-model="addPropertyDialog"/>
            </el-row>

            <el-container direction="vertical" class="prop-section" v-for="(property, idx) in currentUserProps">

                <dom-editable-label v3 :value="property.property.address" :prefix-icon="true" class="property-header" :editable="false">
                    <template v-slot:prefix-icon>
                        <span class="number-icon-prefix">{{ idx + 1 }}</span>
                    </template>
                </dom-editable-label>

                <template v-if="needPropertyDetails">
                    <dom-editable-label v2 :value="property.name" prefix-text="Наименование объекта права:" @input="onSaveUXPField(property.uxpId, 'name', $event)"/>
                    <dom-editable-label v2 :value="property.regDocument" prefix-text="Документ основания права:" @input="onSaveUXPField(property.uxpId, 'regDocument', $event)"/>
                    <dom-editable-label v2 :value="property.address" prefix-text="Адрес основания права:" @input="onSaveUXPField(property.uxpId, 'address', $event)"/>
                    <el-row :gutter="10">
                        <el-col :span="12" :xs="24">
                            <dom-datepicker-label v2 :value="property.regDate" prefix-text="Дата регистрации права:" @input="onSaveUXPField(property.uxpId, 'regDate', $event, 'date')"/>
                        </el-col>
                        <el-col :span="12" :xs="24">
                            <dom-editable-label v2 :value="property.propertyArea" prefix-text="Общая площадь права:" type="number" @input="onSaveUXPField(property.uxpId, 'propertyArea', $event, 'decimal')"/>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12" :xs="24">
                            <dom-editable-label v2 :value="property.egrnNo" prefix-text="Номер ЕГРН:" @input="onSaveUXPField(property.uxpId, 'egrnNo', $event)"/>
                        </el-col>
                        <el-col :span="12" :xs="24">
                            <dom-editable-label v2 :value="property.cadNumber" prefix-text="Кадастровый номер:" @input="onSaveUXPField(property.uxpId, 'cadNumber', $event)"/>
                        </el-col>
                    </el-row>

                    <dom-editable-label v2 :value="property.commonNumber" prefix-text="Условный номер:" @input="onSaveUXPField(property.uxpId, 'commonNumber', $event)"/>
                </template>

                <el-row :gutter="10" type="flex" justify="end">
                    <dom-button2 class="remove-button mb-20" @click="onRemoveProperty(property)">Удалить объект</dom-button2>
                </el-row>

            </el-container>

        </el-container>

    </el-container>

</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import DomEditableLabel from "@/views/editor/DomEditableLabel";
import DomSelectableLabel from "@/views/editor/DomSelectableLabel";
import DomDatepicker from "@/views/elements/DomDatepicker";
import DomDatepickerLabel from "@/views/editor/DomDatepickerLabel";
import DomLongtextLabel from "@/views/editor/DomLongtextLabel";
import DomButton from "@/views/elements/DomButton";
import DomButton2 from "@/views/elements/DomButton2";
import CodeConfirmationDialog from "@/views/dialogs/CodeConfirmationDialog";
import api from "@/api";
import RegisterUserDialog from "@/views/dialogs/RegisterUserDialog";
import AddRealtyDialog from "@/views/dialogs/AddRealtyDialog";

export default {
    components: {
        AddRealtyDialog,
        RegisterUserDialog,
        CodeConfirmationDialog,
        DomButton2,
        DomButton,
        DomLongtextLabel,
        DomDatepickerLabel,
        DomDatepicker,
        DomSelectableLabel,
        DomEditableLabel},
    props: {
        id: {
            require: true
        }
    },

    data: () => ({
        setAdminDialog: false,
        registerUserDialog: false,
        removeUserDialog: false,
        addPropertyDialog: false,
    }),
    mounted() {
        this.update()
    },

    asyncComputed: {
        roles() {
            return this.getRoles()
        }
    },
    computed: {
        ...mapGetters("user", ["uxpType", "needPropertyDetails", "hasProperty", "isUnregistred", "needEmail", "needBirthDate"]),
        ...mapFields("user", ['currentUser', 'currentUserProps', 'me']),
    },

    methods: {
        ...mapActions("user", ["get_user", "get_props", "save_user_role", "save_user_field", "save_uxp_field", "save_property_field", "remove_user_properties"]),
        ...mapActions("meta", ["getRoles"]),

        update() {
            this.get_user(this.id)
            this.get_props(this.id)
        },

        onGetCode() {
            return api.users.getCode(this.id)
        },

        onRemoveUser(code) {
            return api.users.remove(code, this.id)
                .then(() => this.$router.push({name: "users"}))
        },

        onSaveUserRole(value) {
            this.save_user_role({user_id: this.id, role_cd: value})
        },

        onSaveUserField(field, value, datatype) {
            const user_id = this.id
            this.save_user_field({user_id, field, value, datatype})
                .then(() => this.update())
        },

        onAddProperty() {
            this.addPropertyDialog = true;
        },

        onRemoveProperty({uxpId}) {
            const user_id = this.id
            const uxp_id = uxpId

            return this.$confirm(
                `
                    <div style="padding: 10px">
                        <div>Удалить этот объект?</div>
                    </div>`,
                "Удаление!",
                {dangerouslyUseHTMLString: true}
            )
            .then(() => this.remove_user_properties({user_id, uxp_id}))
            .then(() => this.update())
        },

        onSaveUXPField(uxp_id, field, value, datatype) {
            const user_id = this.id
            this.save_uxp_field({user_id, uxp_id, field, value, datatype})
                .then(() => this.update())
        },

        onSavePropertyField(property_id, field, value, datatype) {
            const user_id = this.id
            this.save_property_field({user_id, property_id, field, value, datatype})
                .then(() => this.update())
        },
    },
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.section {
    background-color: $dom-white;
    margin: 10px 0;
    padding: 15px;
}

.header {
    color: $dom-light-blue;
    margin-bottom: 20px;
}

.number-icon-prefix {
    width: 34px;
    height: 34px;
    background-color: $dom-green;
    color: $dom-white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dom-editable-label {
}

.property-header {
    color: $dom-light-blue;
}

.prop-section {
    margin-bottom: 16px;
}

.set-admin-button {
    color: $dom-light-blue;
    border-color: $dom-light-blue;
}

.remove-button,
.remove-button:hover,
.remove-button:active,
.remove-button:focus {
    color: $dom-red;
    border-color: $dom-red;
}

.add-prop-button,
.add-prop-button:hover,
.add-prop-button:active,
.add-prop-button:focus {
    color: $dom-light-blue;
    border-color: $dom-light-blue;
}

</style>

